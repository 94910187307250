import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { store } from '../redux/store';
import { removeUser } from '../redux/userSlice';
import { googleLogout } from '@react-oauth/google';

const Header = ({isHeaderFixed}) => {
    const user = useSelector(state => state.user);

    const handleLogout = async () => {
        try {
            googleLogout();
        } catch (error) { }

        const token = user?.token
        
        fetch('https://api.cellycash.com/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+token
            }
        })

        store.dispatch(removeUser())
    }
    
    return (
        <header className={`${isHeaderFixed ? 'fix_style fixed' : ''}`}> 
            <div className="container">
                <Navbar expand="lg" fixed="top">
                    <Navbar.Brand as={Link} to="/">
                        <img src="/images/logo.svg" alt="CellyCash Logo"/>
                    </Navbar.Brand>
                    <Nav className="ml-auto d-lg-none d-none d-sm-flex toggle-nav" style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Nav.Item className='d-none d-md-block' style={{ marginRight: '10px' }}>
                            {user?.token ? (
                                <NavDropdown title={user?.name?.substring(0, 20)} id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/my-orders">My orders</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/update-profile">Update profile</NavDropdown.Item>
                                    {user.registered_with == 'email' ? <NavDropdown.Item as={Link} to="/change-password">Change password</NavDropdown.Item> : null}
                                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <Link to="/login" className="nav-link">Login</Link>
                            )}
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/instant-cash" className="nav-link dark_btn">Get Instant Cash</Link>
                        </Nav.Item>
                    </Nav>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon">
                            <div className="toggle-wrap">
                                <span className="toggle-bar"></span>
                            </div>
                        </span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="main-nav">
                            <Nav.Item>
                                <Link to="/" className="nav-link">Home</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/instant-cash/apple" className="nav-link">Sell iPhone</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/instant-cash/samsung" className="nav-link">Sell Samsung</Link>
                            </Nav.Item>
                            <Nav.Item className='d-md-none'>
                                {user?.token ? (
                                    <NavDropdown title={user?.name?.substring(0, 20)} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="/my-orders">My orders</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/update-profile">Update profile</NavDropdown.Item>
                                        {user.registered_with == 'email' ? <NavDropdown.Item as={Link} to="/change-password">Change password</NavDropdown.Item> : null}
                                        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                ) : (
                                    <Link to="/login" className="nav-link">Login</Link>
                                )}
                            </Nav.Item>
                            <Nav.Item className='d-sm-none'>
                                <Link to="/instant-cash" className="nav-link dark_btn">Get Instant Cash</Link>
                            </Nav.Item>
                        </Nav>
                        <Nav className="ml-auto d-none d-lg-flex">
                            <Nav.Item>
                                {user?.token ? (
                                    <NavDropdown title={user?.name?.substring(0, 20)} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="/my-orders">My orders</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/update-profile">Update profile</NavDropdown.Item>
                                        {user.registered_with == 'email' ? <NavDropdown.Item as={Link} to="/change-password">Change password</NavDropdown.Item> : null}
                                        <NavDropdown.Item className='text-danger' onClick={handleLogout}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                ) : (
                                    <Link to="/login" className="nav-link">Login</Link>
                                )}
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/instant-cash" className="nav-link dark_btn">Get Instant Cash</Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
    );
}
 
export default Header;
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { store } from '../redux/store';
import { setUser } from '../redux/userSlice';

const ChangePassword = ({navigation}) => {
    const user = useSelector(state => state.user);

    const navigate = useNavigate()

    const mainViewRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)
    const [validationErrors, setValidationErrors] = useState([]);
    const [success, setSuccess] = useState(false)
    
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()

        setIsLoading(true);
        setValidationErrors([]);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('current_password', currentPassword);
        formData.append('new_password', password);
        formData.append('new_password_confirmation', passwordConfirm);
        
        fetch('https://api.cellycash.com/user/change-password', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+user?.token
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message)

                setCurrentPassword('')
                setPassword('')
                setPasswordConfirm('')
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (!user?.token)
        {
            navigate('/')
        }
    }, [user?.token]);

    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div ref={mainViewRef} className="container">
                    <div className="banner_text mb-4">
                        <h2>Change Password</h2>
                    </div>

                    <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                        <form onSubmit={handleSubmit} className='text-left'>
                            { success && <div className="alert-msg-success" style={{ width: '100%' }}><p className="mb-0">{ success }</p></div> }
                            { error && <div className="alert-msg-danger" style={{ width: '100%' }}><p className="mb-0">{ error }</p></div> }
                            { validationErrors.length > 0 &&
                                <div className="alert-msg-danger" style={{ width: '100%' }}>
                                    <h3>There was some error in submitted information, please try again.</h3>
                                    <ul>
                                        { validationErrors.map((validationError, index) => (
                                            <li key={index}>{ validationError }</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            
                            <p className='form-label'>Your current password</p>
                            <input
                                value={currentPassword}
                                onChange={ (e) => setCurrentPassword(e.target.value) }
                                className='form-control'
                                placeholder='Enter your current password'
                                type='password'
                                required
                            />

                            <p className='form-label'>Your new password</p>
                            <input
                                value={password}
                                onChange={ (e) => setPassword(e.target.value) }
                                className='form-control'
                                placeholder="Enter your new password"
                                type='password'
                                required
                            />

                            <p className='form-label'>Confirm password</p>
                            <input
                                value={passwordConfirm}
                                onChange={ (e) => setPasswordConfirm(e.target.value) }
                                className='form-control'
                                placeholder="Re-enter your new password"
                                type='password'
                                required
                            />
                                
                            <div className="text-center">
                                <button type='submit' disabled={isLoading} className="yellow-button">{isLoading ? 'Please wait...' : 'Change Password'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default ChangePassword;
import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from "./Header";
import Footer from "./Footer";

import Home from "./Home";
import InstantCashBrand from "./InstantCashBrand";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import MyOrders from "./MyOrders";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";

const App = () => {
    useEffect(() => {
        AOS.init({
            once: true
        });
    }, [])

    return ( 
        <>
            <Routes>
                <Route element={<AppLayout />} >
                    <Route path="/" element={<Home />} />
                    <Route path="/instant-cash" element={<InstantCashBrand />} />
                    <Route path="/instant-cash/:quickBrand" element={<InstantCashBrand />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/my-orders" element={<MyOrders />} />                   
                    <Route path="/update-profile" element={<UpdateProfile />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                </Route>
            </Routes>
        </>
    );
}

const AppLayout = () => {
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 250) {
                setIsHeaderFixed(true);
            } else {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`page_wrapper`}>
            <Header isHeaderFixed={isHeaderFixed} />
            
            <Outlet />

            <Footer />
        </div>
    )
};
 
export default App;
import { Link } from 'react-router-dom';

const PrivacyPolicy = ({navigation}) => {
    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div className="container">
                    <div className="banner_text mb-4">
                        <h2>Privacy Policy</h2>
                    </div>
                    
                    <div style={{ fontSize:'14px', textAlign: 'left' }}>
                        <h3 style={{ fontSize: '18px' }} className='mb-2'>CellyCash privacy policy:</h3>
                        <p>We may handle your personal data in connection with your use of the Platform. This privacy notice (together with our Terms and Conditions) set out, for the Platform, our collection and sharing practices, the uses to which personal data is put, the ways in which we protect it in accordance with the data protection laws and your privacy rights. Please read it carefully.</p>
                        <p class="mb-1">This Statement applies to Personal Data processed by CellyCash when you:</p>
                        <ul className='unstyled' style={{marginBottom: '10px'}}>
                            <li className='unstyled'>Visit this website (<a className='link-blue' href="www.cellycash.com">www.cellycash.com</a>) and/or any other CellyCash website(s) which reference or link to this Statement (collectively, “Website”).</li>
                            <li className='unstyled'>Use, download, access, as applicable, any of our various internet-based offerings, including mobile platforms, software, or applications (collectively, “Services”).</li>
                            <li className='unstyled'>Visit CellyCash’s branded social media sites.</li>
                            <li className='unstyled'>Receive communications from us, including emails, phone calls, or other electronic messages; or Data we collect.</li>
                        </ul>

                        <p class="mb-1">We collect some information directly from you (for example, via forms you complete or products or Services you access, download, or otherwise obtain). Such information is generally limited to:</p>
                        <ul className='unstyled' style={{marginBottom: '10px'}}>
                            <li className='unstyled'>Name, Email ID, Phone Number, State, City, Address.</li>
                            <li className='unstyled'>Your communications with CellyCash personnel.</li>
                            <li className='unstyled'>Content you post on our social media sites.</li>
                            <li className='unstyled'>Information you provide on the Website, such as online questionnaires, or feedback forms.</li>
                            <li className='unstyled'>Information you provide when you subscribe to sms services.</li>
                            <li className='unstyled'>Information you provide when you create your account, log-in credentials and information about your use of and preferences for the Services.</li>
                            <li className='unstyled'>Other information is received indirectly from you via use of the Services (for example, from observing your actions on the Website or to provision you with account access).</li>
                        </ul>

                        <p class="mb-1">Such information is generally limited to:</p>
                        <p>Information regarding usage of the Services or Website via web logs collected automatically and passively using various technologies, which generally will not specifically identify you or other individuals. Examples may include IP addresses, Device details, browser types, domain names, and other anonymous statistical data regarding aggregate usage.</p>
                        <p>Lastly, we also collect Personal Data via other sources such as public records, publicly available sources or internet sites, vendors, data suppliers and service providers, commercially available marketing lists or registries, telephone directories, social network (such as LinkedIn), news outlets and related media. Such Personal Data collected via these sources is limited to business contact information, such as names, contact information, job titles, IP addresses, and LinkedIn and other social media profiles.</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Use of Personal Data</h3>
                        <p>CellyCash uses your Personal Data when: it is necessary to perform our obligations or exercise our contractual rights; we have a legitimate business interest to process such Personal Data; it is necessary to comply with applicable laws or regulations; we have your consent (where required under applicable law) to use your information (where we rely on your consent, you have the right to withdraw consent by contacting us as set forth below).</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Rights and Obligations with respect to your Data</h3>
                        <p>You have a number of rights under data protection laws in relation to the way we process your personal data. These are set out below. You may contact us using the details in section 14 below to exercise any of these rights. We will respond to any request received from you within one month from the date of the request.</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>DESCRIPTION OF RIGHTS</h3>
                        <ul className='unstyled' style={{marginBottom: '10px'}}>
                            <li className='unstyled'>Right 1 – A right to access personal data held by us about you.</li>
                            <li className='unstyled'>Right 2 – A right to require us to rectify any inaccurate personal data held by us about you.</li>
                            <li className='unstyled'>Right 3 – A right to require us to erase personal data held by us about you. This right will only apply where (for example): we no longer need to use the personal data to achieve the purpose we collected it for; or where you withdraw your consent (if we are using your personal data based on your consent); or where you object to the way we process your personal data (in line with Right 6 below).</li>
                            <li className='unstyled'>Right 4 – A right to restrict our processing of personal data held by us about you. This right will only apply where (for example): you dispute the accuracy of the personal data held by us; or where you would have the right to require us to erase the personal data but would prefer that our processing is restricted instead; or where we no longer need to use the personal data to achieve the purpose we collected it for, but you require the data for the purposes of dealing with legal claims.</li>
                            <li className='unstyled'>Right 5 – A right to receive personal data, which you have provided to us, in a structured, commonly used and machine-readable format. You also have the right to require us to transfer this personal data to another organization, at your request.</li>
                            <li className='unstyled'>Right 6 – A right to object to our processing of your personal data (including for the purposes of sending promotional messages to you).</li>
                            <li className='unstyled'>Right 7 – A right to withdraw your consent, where we are relying on it to use your personal data (for example, to provide you with promotional messages about our services or products). If you withdraw your consent, we may not be able to provide certain products or services to you.</li>
                        </ul>

                        <p>These rights are subject to certain exemptions to safeguard the public interest (e.g. the prevention or detection of crime) and our interests (e.g. the maintenance of legal privilege).</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Updating information</h3>
                        <p>Keeping your information accurate and up to date is very important. Inaccurate or incomplete information could impair our ability to deliver relevant services to you. We will use reasonable endeavors to ensure that your personal data is accurate. In order to assist us with this, you should notify us of any changes to your personal data by updating your profile on the Platform (App) or by contacting us as set out in section 14 below. Intentionally providing false or misleading information or using another person’s email address or personal data for the purposes of falsely obtaining any products or services through the Platform, may lead to termination of access to the Platform and may result in legal action.</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Modifications to Privacy Notice</h3>
                        <p>We may change the content of the Platform and how we use cookies and consequently this privacy notice and our cookies notice and any other document to which they refer are subject to change at any time. If we make material updates to this privacy notice, we will update the date it was last changed and will clearly indicate in the document. Any changes we make to this privacy notice become effective immediately when we post the revised privacy notice on the Platform. We recommend that you review this privacy notice regularly for changes. This privacy notice was last updated on 10th May 2021.</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Questions Regarding this Statement and Your Information</h3>
                        <p>If you have any questions or comments regarding this Statement or your information, please contact us at <a className='link-blue' href="mailto:info@cellycash.com">info@cellycash.com</a></p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>How to complain</h3>
                        <p class="mb-0">Please let us know if you are unhappy with how we have used your personal data or are not satisfied with our handling of any request by you in relation to your rights. You can contact us using the contact details above. You also have the right to complain to the relevant supervisory authority.</p>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default PrivacyPolicy;
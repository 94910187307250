import Modal from 'react-bootstrap/Modal';

const MyOrdersDetailsModal = ({order, setOrder}) => {
    const handleClose = () => {
        setOrder(null)
    }

    return (
        <Modal size='lg' show={order !== null} onHide={handleClose}>
            <div className='information-box'>
                <button type="button" onClick={handleClose} class="btn-close" aria-label="Close"></button>
                <div className='row row-cols-1 row-cols-lg-2'>
                    <div className='col' style={{ flex: '0 0 calc(100% - 25px)', maxWidth: 'calc(100% - 25px)' }}><p className='mb-2'>Below are the details you submitted.</p></div>
                    <div className='col'><p className='information-label'>Request number: <br className='d-sm-none' /><span>{order?.order_number}</span></p></div>
                    <div className='col'><p className='information-label'>Request date: <br className='d-sm-none' /><span>{order?.request_date}</span></p></div>
                    <div className='col'><p className='information-label'>Model: <br className='d-sm-none' /><span>{order?.title}</span></p></div>
                    <div className='col'><p className='information-label'>Name: <br className='d-sm-none' /><span>{order?.name}</span></p></div>
                    <div className='col'><p className='information-label'>Email: <br className='d-sm-none' /><span>{order?.email}</span></p></div>
                    <div className='col'><p className='information-label'>Mobile number: <br className='d-sm-none' /><span>{order?.mobile_number}</span></p></div>
                    <div className='col'><p className='information-label'>State: <br className='d-sm-none' /><span>{order?.state}</span></p></div>
                    <div className='col'><p className='information-label'>City: <br className='d-sm-none' /><span>{order?.city}</span></p></div>
                    <div className='col'><p className='information-label'>Address: <br className='d-sm-none' /><span>{order?.address}</span></p></div>
                    <div className='col'><p className='information-label'>Provider: <br className='d-sm-none' /><span>{order?.provider}</span></p></div>
                    <div className='col'><p className='information-label'>Phone broken: <br className='d-sm-none' /><span>{order?.is_broken} {order?.broken_sides ? '('+order?.broken_sides+')' : ''}</span></p></div>
                    {order?.is_broken == 'No' ? (
                        <>
                            <div className='col'><p className='information-label'>Phone condition: <br className='d-sm-none' /><span>{order?.q1}</span></p></div>
                            <div className='col'><p className='information-label'>Screen scratches condition: <br className='d-sm-none' /><span>{order?.q2}</span></p></div>
                            <div className='col'><p className='information-label'>Battery health: <br className='d-sm-none' /><span>{order?.q4}</span></p></div>
                            <div className='col'><p className='information-label'>Body damage: <br className='d-sm-none' /><span>{order?.q5}</span></p></div>
                            <div className='col'><p className='information-label'>Phone back condition: <br className='d-sm-none' /><span>{order?.q6}</span></p></div>
                        </>
                    ) : null}
                    <div className='col'><p className='information-label'>Original charger available: <br className='d-sm-none' /><span>{order?.charger}</span></p></div>
                    <div className='col'><p className='information-label'>Original IMEI box available: <br className='d-sm-none' /><span>{order?.box}</span></p></div>
                    <div className='col'><p className='information-label'>{order?.issues ? 'Issues with' : 'Issues'}: <br className='d-sm-none' /><span>{order?.issues ? order?.issues : 'No issue'}</span></p></div>
                    <div className='col text-center' style={{ flex: '0 0 100%', maxWidth: '100%' }}><button onClick={handleClose} style={{ minWidth: '100px' }} className="yellow-button mb-0 mt-2">Close</button></div>
                </div>
            </div>
        </Modal>
    );
}
 
export default MyOrdersDetailsModal;
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="top_footer" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <div className="abt_side">
                                <div className="logo"><Link to="/"><img src="/images/logo.svg" alt="CellyCash Logo"/></Link></div>
                                <p className="footer-about">Discover a hassle-free way to sell your old Apple and Samsung phones and receive instant cash for your valuable devices.</p>
                                <ul className="contact-links">
                                    <li><a href="mailto:info@cellycash.com"><img src="/images/icon-email.svg" alt="Email"/>info@cellycash.com</a></li>
                                    <li><a href="tel:+14154290937"><img src="/images/icon-phone.svg" alt="Phone"/>(+1) 415 429 0937</a></li>
                                    <li><a href="https://www.facebook.com/cellycash"><img src="/images/icon-facebook.svg" alt="Facebook"/>cellycash</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                            <div className="links useful-links">
                                <h3>Useful Links</h3>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/instant-cash">Get Instant Cash</Link></li>
                                    <li><Link to="/terms-conditions">Terms &amp; conditions</Link></li>
                                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                            <div className="try_out">
                                <h3>Let’s Try Out</h3>
                                <ul className="app_btn">
                                    <li>
                                        <Link to="/instant-cash" className="instant-cash-button green">Get Instant Cash</Link>
                                    </li>
                                    {/* <li>
                                        <a href="https://play.google.com/store/apps/details?id=com.apnaphone.app" className="banner-download"><img src="/images/google-play-button.svg" alt="Download button"/></a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom_footer">
                <div className="container">
                    <p>© Copyrights 2023. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
 
export default Footer;
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { store } from '../redux/store';
import { setUser, updateUser } from '../redux/userSlice';
import useFetch from '../useFetch';
import MyOrdersDetailsModal from './MyOrderDetailsModal';

const MyOrders = ({navigation}) => {
    const user = useSelector(state => state.user);

    const navigate = useNavigate()

    const [items, setItems] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null)
    
    const { response, isLoading, error } = useFetch('https://api.cellycash.com/user/my-orders', user?.token);

    useEffect(() => {
        setItems(response?.orders ?? [])
    }, [response])

    useEffect(() => {
        if (!user?.token)
        {
            navigate('/')
        }
    }, [user?.token]);

    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div className="container">
                    <div className="banner_text mb-4">
                        <h2>My Orders</h2>
                    </div>

                    <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                        {isLoading ? <p style={{ fontSize: '17px', lineHeight: '22px', fontWeight: '500' }}>Loading data...</p> : null}                        
                        { error && <div className="alert-msg-danger" style={{ width: '100%' }}><p className="mb-0">{ error }</p></div> }                        
                        {!isLoading && !error && items?.length === 0 ? <div className="alert-msg-danger" style={{ width: '100%', backgroundColor: '#c38903' }}><p className="mb-0">You don't have any order.</p></div> : null}

                        {!isLoading && !error ? (
                            items?.map(item => (
                                <div key={item.id} className='text-left mb-3' style={{ backgroundColor: '#f6f4fe', padding: '10px 15px', border: '1px solid #e5e3ed', borderRadius: '5px', boxShadow: 'rgba(107, 137, 118, 0.1) 2px 3px 4px' }}>
                                    <>
                                        <div className='mb-1 d-flex align-items-center justify-content-between'>
                                            <p className='mb-0' style={{ flex: 1, color: '#0e9877', fontWeight: '500' }}>{item.title}</p>
                                            <p className="mb-0 link-blue" onClick={() => setSelectedOrder(item)} style={{ fontSize: '14px', marginLeft: 'auto', textAlign: 'right' }}>View Details</p>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-between'>
                                            <p className="mb-0" style={{ flex: 1, fontWeight: '500' }}>{item.order_number}</p>
                                            <p className="mb-0" style={{ fontSize: '14px', marginLeft: 'auto', textAlign: 'right' }}>{item.request_date}</p>
                                        </div>
                                    </>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            </section>

            <MyOrdersDetailsModal
                order={selectedOrder}
                setOrder={setSelectedOrder}
            />
        </>
    );
}
 
export default MyOrders;
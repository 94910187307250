import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import useFetch from '../useFetch';
import FaqsSection from './FaqsSection';
import { Icon } from '@iconify/react';

const providers = [ 'AT&T', 'Factory Unlocked', 'Sprint', 'T-Mobile', 'Verizon' ]
const brokenOptions = [ 'Front Broken', 'Back Broken', 'Both Broken' ]
const conditions1 = [ 'Mint', 'Good', 'Used' ]
const conditions2 = [ 'Scratchless', 'Minor', 'Major' ]
const conditions4 = [ 'Above 80%', 'Above 85%', 'Above 90%' ]
const conditions5 = [ 'No Dents', 'Minor', 'Major' ]
const conditions6 = [ 'No Scratches', 'Minor Scratches', 'Major Scratches' ]
const issuesItems = [
    { name: 'Microphone', icon: '/images/issues/mic.svg' },
    { name: 'Speaker', icon: '/images/issues/speaker.svg' },
    { name: 'Camera', icon: '/images/issues/camera.svg' },
    { name: 'Audio Receiver', icon: '/images/issues/audio.svg' },
    { name: 'Bluetooth', icon: '/images/issues/bluetooth.svg' },
    { name: 'Charging Port', icon: '/images/issues/chargingport.svg' },
    { name: 'Wifi Signal', icon: '/images/issues/wifi.svg' },
    { name: 'Face ID', icon: '/images/issues/faceid.svg' },
    { name: 'Fingerprint', icon: '/images/issues/fingerprint.svg' },
    { name: 'Heat Up', icon: '/images/issues/heatup.svg' },
]

const InstantCashBrand = () => {
    document.title = "Get Instant Cash - CellyCash";
    const { quickBrand } = useParams();

    const mainViewRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)
    const [instantCash, setInstantCash] = useState(null)
    const [validationErrors, setValidationErrors] = useState([]);
    const [conditionError, setConditionError] = useState(null)
    const [issueError, setIssueError] = useState(null)
    const [accessoryError, setAccessoryError] = useState(null)

    const [estimatedPrice, setEstimatedPrice] = useState(0)
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');

    const [step, setStep] = useState(1)
    const [brand, setBrand] = useState(null)
    const [model, setModel] = useState(null)
    const [variant, setVariant] = useState(null)
    const [provider, setProvider] = useState('')
    const [isBroken, setIsBroken] = useState(null)
    const [brokenSides, setBrokenSides] = useState('')
    const [condition1, setCondition1] = useState('')
    const [condition2, setCondition2] = useState('')
    //const [condition3, setCondition3] = useState('')
    const [condition4, setCondition4] = useState('')
    const [condition5, setCondition5] = useState('')
    const [condition6, setCondition6] = useState('')
    const [issues, setIssues] = useState(null)
    const [hasBox, setHasBox] = useState(null)
    const [hasCharger, setHasCharger] = useState(null)
    
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [variants, setVariants] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([])
    const [deductions, setDeductions] = useState(null);

    const [isEmailConsent, setIsEmailConsent] = useState(true)
    const [isPhoneConsent, setIsPhoneConsent] = useState(true)

    const { response, isLoading: isLoadingBrand, error: fetchError } = useFetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash');

    useEffect(() => {
        if (quickBrand)
        {
            if (response?.brands?.length > 0)
            {
                var quickBrandObj = response?.brands.find(item => item.name.toLowerCase() == quickBrand)
                if (quickBrandObj)
                {
                    setBrand(quickBrandObj)
                    setStep(2)
                    setModels(quickBrandObj.mobiles ?? [])
                }
            }
        }
        else
        {
            setBrand(null)
            setStep(1)
            setModels([])
        }
    }, [quickBrand])

    useEffect(() => {
        if (response?.brands?.length > 0)
        {
            setBrands(response?.brands ?? [])
            setStates(response?.states ?? [])
            setDeductions(response?.deductions ?? null)
            
            if (quickBrand)
            {
                var quickBrandObj = response?.brands.find(item => item.name.toLowerCase() == quickBrand)
                if (quickBrandObj)
                {
                    setBrand(quickBrandObj)
                    setStep(2)
                    setModels(quickBrandObj.mobiles ?? [])
                }
            }
        }
    }, [response])
    
    const handleReset = () => {
        setIsLoading(false);
        setError(null)
        setValidationErrors([]);
        setInstantCash(null)
        setConditionError(null)
        setIssueError(null)
        setAccessoryError(null)

        setCities([])

        setIsEmailConsent(true)
        setIsPhoneConsent(true)

        setName('')
        setEmail('')
        setMobileNumber('')
        setState('');
        setCity('');
        setAddress('');

        setStep(1)
        setBrand(null)
        setModel(null)
        setVariant(null)
        setProvider('')
        setIsBroken(null)
        setBrokenSides('')
        setCondition1('')
        setCondition2('')
        //setCondition3('')
        setCondition4('')
        setCondition5('')
        setCondition6('')
        setIssues(null)
        setHasBox(null)
        setHasCharger(null)

        mainViewRef.current.scrollIntoView();
    }

    const handleBackStep = () => {
        if (step == 2)
        {
            setStep(1)
            setModel(null)
        }
        if (step == 3)
        {
            setStep(2)
            setVariant(null)
        }
        if (step == 4)
        {
            if (model.has_variants)
            {
                setStep(3)
            }
            else
            {
                setStep(2)
            }
            setProvider('')
        }
        if (step == 5)
        {
            setStep(4)
            setIsBroken(null)
            setBrokenSides('')
        }
        if (step == 6)
        {
            setStep(5)
            setCondition1('')
            setCondition2('')
            //setCondition3('')
            setCondition4('')
            setCondition5('')
            setCondition6('')
            setConditionError(null)
        }
        if (step == 7)
        {
            if (isBroken)
            {
                setStep(5)
            }
            else
            {
                setStep(6)
            }
            setIssues(null)
            setIssueError(null)
        }
        if (step == 8)
        {
            setStep(7)
            setHasBox(null)
            setHasCharger(null)
            setAccessoryError(null)
        }
        if (step == 9)
        {
            setStep(8)
        }

        mainViewRef.current.scrollIntoView();
    }

    useEffect(() => {
        if (state) setCities(states.find(value => value.name == state).cities)
    }, [state])

    const handleBrand = (item) => {
        setBrand(item)
        setStep(2)
        setModels(item.mobiles ?? [])

        mainViewRef.current.scrollIntoView();
    }

    const handleModel = (item) => {
        setModel(item)
        if (item.has_variants)
        {
            setVariants(item.variants)
            setStep(3)
        }
        else
        {
            setStep(4)
        }

        mainViewRef.current.scrollIntoView();
    }

    const handleVariant = (item) => {
        setVariant(item)
        setStep(4)

        mainViewRef.current.scrollIntoView();
    }

    const handleProvider = (item) => {
        setProvider(item)
        setStep(5)

        mainViewRef.current.scrollIntoView();
    }

    const handleBroken = (isBroken) => {
        setIsBroken(isBroken)

        if (!isBroken)
        {
            setBrokenSides('')
            setStep(6)
        }

        mainViewRef.current.scrollIntoView();
    }

    const handleBrokenSide = (item) => {
        setBrokenSides(item)
        setStep(7)

        mainViewRef.current.scrollIntoView();
    }

    const handleCondition = () => {
        setConditionError(null)
        if (!condition1) { setConditionError('Please select phone condition'); return }
        if (!condition2) { setConditionError('Please select screen scratches condition'); return }
        //if (!condition3) { setConditionError('Please select screen condition'); return }
        if (!condition4) { setConditionError('Please select battery status'); return }
        if (!condition5) { setConditionError('Please select body damage'); return }
        if (!condition6) { setConditionError('Please select phone back condition'); return }

        setStep(7)

        mainViewRef.current.scrollIntoView();
    }

    const handleIssueSubmit = () => {
        setIssueError(null)
        if (issues === null) { setIssueError('Please select an option'); return }
        
        setStep(8);
        
        mainViewRef.current.scrollIntoView();
    }

    const handleIssue = (item) => {
        setIssues(issues => {
            if (issues === null) return [item]
            if (issues.includes(item)) return issues.filter(issue => issue != item)
            else return [...issues, item]
        })
    }

    const handleAccessory = () => {
        setAccessoryError(null)
        if (hasCharger === null) { setAccessoryError('Please choose an option for charger'); return }
        if (hasBox === null) { setAccessoryError('Please choose an option for box'); return }

        var price = 0

        if(provider == 'AT&T')
        {
            price = (variant?.price_at_t ?? model?.price_at_t)
        }
        else if(provider == 'Factory Unlocked')
        {
            price = (variant?.price_factory_unlocked ?? model?.price_factory_unlocked)
        }
        else if(provider == 'Sprint')
        {
            price = (variant?.price_sprint ?? model?.price_sprint)
        }
        else if(provider == 'T-Mobile')
        {
            price = (variant?.price_tmobile ?? model?.price_tmobile)
        }
        else if(provider == 'Verizon')
        {
            price = (variant?.price_verizon ?? model?.price_verizon)
        }
        
        if (deductions != null)
        {
            if (brokenSides == 'Front Broken') { price *= (1 - deductions.broken_front) }
            if (brokenSides == 'Back Broken') { price *= (1 - deductions.broken_back) }
            if (brokenSides == 'Both Broken') { price *= (1 - deductions.broken_both) }
            if (condition1 == 'Mint') { price *= (1 - deductions.conditions1_mint) }
            if (condition1 == 'Good') { price *= (1 - deductions.conditions1_good) }
            if (condition1 == 'Used') { price *= (1 - deductions.conditions1_used) }
            if (condition2 == 'Scratchless') { price *= (1 - deductions.conditions2_no) }
            if (condition2 == 'Minor') { price *= (1 - deductions.conditions2_minor) }
            if (condition2 == 'Major') { price *= (1 - deductions.conditions2_major) }
            if (condition4 == 'Above 80%') { price *= (1 - deductions.conditions4_80) }
            if (condition4 == 'Above 85%') { price *= (1 - deductions.conditions4_85) }
            if (condition4 == 'Above 90%') { price *= (1 - deductions.conditions4_90) }
            if (condition5 == 'No Dents') { price *= (1 - deductions.conditions5_no) }
            if (condition5 == 'Minor') { price *= (1 - deductions.conditions5_minor) }
            if (condition5 == 'Major') { price *= (1 - deductions.conditions5_major) }
            if (condition6 == 'No Scratches') { price *= (1 - deductions.conditions6_no) }
            if (condition6 == 'Minor Scratches') { price *= (1 - deductions.conditions6_minor) }
            if (condition6 == 'Major Scratches') { price *= (1 - deductions.conditions6_major) }
            if (issues?.length > 0) { price *= (1 - deductions.issues) }
        }

        setEstimatedPrice('$'+(Math.ceil(price / 5) * 5))
        
        setStep(9)

        mainViewRef.current.scrollIntoView();
    }

    const handleEmailConsent = () => {
        setIsEmailConsent(current => !current)
    }

    const handlePhoneConsent = () => {
        setIsPhoneConsent(current => !current)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors([]);
        setError(false);

        let formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile_number', mobileNumber);
        formData.append('state', state);
        formData.append('city', city);
        formData.append('address', address);
        if (variant) formData.append('variant', variant?.id);
        formData.append('provider', provider);
        if (isBroken) formData.append('is_broken', 'yes');
        formData.append('broken_sides', brokenSides);
        formData.append('q1', condition1);
        formData.append('q2', condition2);
        //formData.append('q3', condition3);
        formData.append('q4', condition4);
        formData.append('q5', condition5);
        formData.append('q6', condition6);
        if (issues !== null)
        {
            issues.map(issue => {
                formData.append('h[]', issue);
            })
        }
        if (hasCharger) formData.append('charger', 'yes');
        if (hasBox) formData.append('box', 'yes');
        if (isEmailConsent) formData.append('is_email_consent', 'yes');
        if (isPhoneConsent) formData.append('is_phone_consent', 'yes');
        formData.append('estimated_price', estimatedPrice);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash/'+model?.id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            mainViewRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setStep(10)
                
                setInstantCash(data.data)
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            mainViewRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }
    
    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div ref={mainViewRef} className="container">
                    <div data-aos="fade-right" data-aos-duration="1500">
                        {step != 10 ? (
                            <div className="instant-cash-progress">
                                Step {step} of 9
                                <span className={`progress progress-${step}`}>
                                    <span className={`step step-1 ${step>=1 ? 'active' : ''}`}/>
                                    <span className={`step step-2 ${step>=2 ? 'active' : ''}`}/>
                                    <span className={`step step-3 ${step>=3 ? 'active' : ''}`}/>
                                    <span className={`step step-4 ${step>=4 ? 'active' : ''}`}/>
                                    <span className={`step step-5 ${step>=5 ? 'active' : ''}`}/>
                                    <span className={`step step-6 ${step>=6 ? 'active' : ''}`}/>
                                    <span className={`step step-7 ${step>=7 ? 'active' : ''}`}/>
                                    <span className={`step step-8 ${step>=8 ? 'active' : ''}`}/>
                                    <span className={`step step-9 ${step>=9 ? 'active' : ''}`}/>
                                </span>
                            </div>
                        ) : <div className="instant-cash-progress"></div>}
                        
                        {step != 1 && step != 10 ? <span onClick={handleBackStep} className='instant-cash-btn-go-back'>Go Back</span> : null}
                        {step == 1 ? <p className='instant-cash-phone-title'>Sell your old phone for cash</p> : null}
                        {step != 1 && step != 10 ? <p className='instant-cash-phone-title'>Sell your {step > 2 ? model?.name : brand?.name} for cash</p> : null}
                        
                        { fetchError && <div className="alert-msg-danger"><p className="mb-0">{ fetchError }</p></div> }
                        { error && step != 9 && <div className="alert-msg-danger"><p className="mb-0">{ error }</p></div> }
                        
                        <div className={`${step == 1 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Brand</h2>
                                <p>Choose the brand of your mobile phone.</p>
                            </div>

                            {isLoadingBrand ? (
                                <p className='loading-msg'>Loading data...</p>
                            ) : (
                                brands?.length > 0 ? (
                                    <div className='d-flex flex-wrap justify-content-center'>
                                        {brands.map(item => (
                                            <div key={item.id} onClick={() => handleBrand(item)} className='brand-box'>
                                                <img src={item.image} />
                                                <p>{item.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null
                            )}
                        </div>

                        <div className={`${step == 2 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Model</h2>
                                <p>Choose your mobile phone.</p>
                            </div>
                            <div className='row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 justify-content-center row-cols-xl-6 row-10px limit-width'>
                                {models.map(item => (
                                    <div key={item.id} className='col'>
                                        <div onClick={() => handleModel(item)} className='model-box'>
                                            <img src={item.thumb_image} />
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={`${step == 3 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Storage</h2>
                                <p>Choose your phone storage.</p>
                            </div>
                            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center row-9px'>
                                {variants.map(item => (
                                    <div key={item.id} className='col'>
                                        <div onClick={() => handleVariant(item)} className={`variant-box ${variant?.id == item.id ? 'active' : ''}`}>
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={`${step == 4 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Provider</h2>
                                <p>Choose your provider.</p>
                            </div>

                            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center row-9px'>
                                {providers.map(item => (
                                    <div key={item} className='col'><div onClick={() => handleProvider(item)} className={`variant-box ${provider == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                ))}
                            </div>

                            <div style={{ marginTop: '20px', padding: '10px', borderRadius: '8px', backgroundColor: '#c3890313' }}>
                                <p style={{ color: '#c38903', fontSize: 16, margin: '0px', textAlign: 'center' }}>Disclaimer: We do not buy stolen or black listed phones.</p>
                            </div>
                        </div>

                        <div className={`${step == 5 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Body Condition</h2>
                                <p>Tell us about your phone body.</p>
                            </div>

                            <p className='mb-1 condition-heading'>Is your phone broken?</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => handleBroken(true)} className={`variant-box ${isBroken === true ? 'active' : ''}`}><p>Yes</p></div></div>
                                <div className='col'><div onClick={() => handleBroken(false)} className={`variant-box ${isBroken === false ? 'active' : ''}`}><p>No</p></div></div>
                            </div>
                        
                            {isBroken === true ? (
                                <>
                                    <p className='mb-1 condition-heading'>Broken condition</p>
                                    <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                        {brokenOptions.map(item => (
                                            <div key={item} className='col'><div onClick={() => handleBrokenSide(item)} className={`variant-box ${brokenSides == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                        ))}
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <div className={`${step == 6 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Condition</h2>
                                <p>Tell us about your phone condition.</p>
                            </div>

                            <p className='mb-1 condition-heading'>Phone condition</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                {conditions1.map(item => (
                                    <div key={item} className='col'><div onClick={() => setCondition1(item)} className={`variant-box ${condition1 == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                ))}
                            </div>
                            <p className='mb-1 condition-heading'>Screen scratches condition</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                {conditions2.map(item => (
                                    <div key={item} className='col'><div onClick={() => setCondition2(item)} className={`variant-box ${condition2 == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                ))}
                            </div>
                            <p className='mb-1 condition-heading'>Battery health</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                {conditions4.map(item => (
                                    <div key={item} className='col'><div onClick={() => setCondition4(item)} className={`variant-box ${condition4 == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                ))}
                            </div>
                            <p className='mb-1 condition-heading'>Body damage</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                {conditions5.map(item => (
                                    <div key={item} className='col'><div onClick={() => setCondition5(item)} className={`variant-box ${condition5 == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                ))}
                            </div>
                            <p className='mb-1 condition-heading'>Phone back condition</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                {conditions6.map(item => (
                                    <div key={item} className='col'><div onClick={() => setCondition6(item)} className={`variant-box ${condition6 == item ? 'active' : ''}`}><p>{item}</p></div></div>
                                ))}
                            </div>
                            
                            { conditionError && <div><div className="alert-msg-danger"><p className="mb-0">{ conditionError }</p></div></div> }
                            <button onClick={handleCondition} className="yellow-button">Go to next step</button>
                        </div>

                        <div className={`${step == 7 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Issues</h2>
                                <p>If your phone has issues, select them.</p>
                            </div>

                            <div className='row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 padding-10-percent-xl row-9px justify-content-center mb-2'>
                                <div className='col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12'><div onClick={() => {
                                    setIssues([])
                                }} className={`issue-box no-issue ${issues?.length == 0 ? 'active-green' : ''}`}>
                                    <div className="img-container mx-0"><img src='/images/issues/none.svg' /></div>
                                    <p className='mt-0'>My phone has no issue</p>
                                </div></div>
                                {issuesItems.map(item => (
                                    <div key={item.name} className='col'><div onClick={() => handleIssue(item.name)} className={`issue-box ${issues?.includes(item.name) ? 'active' : ''}`}>
                                        <div className="img-container"><img src={item.icon} /></div>
                                        <p>{item.name}</p>
                                    </div></div>
                                ))}
                            </div>
                            
                            { issueError && <div><div className="alert-msg-danger"><p className="mb-0">{ issueError }</p></div></div> }
                            <button onClick={handleIssueSubmit} className="yellow-button">Go to next step</button>
                        </div>

                        <div className={`${step == 8 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Box & Charger</h2>
                                <p>Select if you have original IMEI box & original charger.</p>
                            </div>

                            <p className='mb-1 condition-heading'>Do you have original charger</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-3'>
                                <div className='col'><div onClick={() => setHasCharger(true)} className={`variant-box ${hasCharger === true ? 'active' : ''}`}><p>Yes</p></div></div>
                                <div className='col'><div onClick={() => setHasCharger(false)} className={`variant-box ${hasCharger === false ? 'active' : ''}`}><p>No</p></div></div>
                            </div>
                            <p className='mb-1 condition-heading'>Do you have original IMEI box</p>
                            <div className='row row-cols-1 row-cols-sm-3 justify-content-center row-9px mb-2'>
                                <div className='col'><div onClick={() => setHasBox(true)} className={`variant-box ${hasBox === true ? 'active' : ''}`}><p>Yes</p></div></div>
                                <div className='col'><div onClick={() => setHasBox(false)} className={`variant-box ${hasBox === false ? 'active' : ''}`}><p>No</p></div></div>
                            </div>
                            
                            { accessoryError && <div><div className="alert-msg-danger"><p className="mb-0">{ accessoryError }</p></div></div> }
                            <button onClick={handleAccessory} className="yellow-button">Go to last step</button>
                        </div>

                        <div className={`${step == 9 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Contact Information</h2>
                                <p>Provide your contact information so we can give you exact value of your phone.</p>
                            </div>

                            <div className="banner_text">
                                {brand?.name && model?.name ? (
                                    <p className='estimated-cost'>Get Upto<br /><span>{estimatedPrice}</span></p>
                                ) : null}
                            </div>

                            { error && step == 9 && <div className="alert-msg-danger"><p className="mb-0">{ error }</p></div> }
                            
                            { validationErrors.length > 0 &&
                                <div className="alert-msg-danger">
                                    <h3>There was some error in submitted information, please try again.</h3>
                                    <ul>
                                        { validationErrors.map((validationError, index) => (
                                            <li key={index}>{ validationError }</li>
                                        ))}
                                    </ul>
                                </div>
                            }

                            <form onSubmit={handleSubmit} className='text-left'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-9'>
                                        <div className='row row-cols-1 row-cols-md-2 align-items-start row-9px mb-2'>
                                            <div className='col col-md-12'>
                                                <p className='form-label'>Your full name</p>
                                                <input
                                                    value={name}
                                                    onChange={ (e) => setName(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your full name'
                                                    required />
                                            </div>

                                            <div className='col'>
                                                <p className='form-label'>Your email address</p>
                                                <input
                                                    value={email}
                                                    onChange={ (e) => setEmail(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your email address'
                                                    type='email'
                                                    required />

                                                <div className="d-flex" style={{ marginTop: '-13px', marginBottom: '20px', fontSize: '14px', lineHeight: '19px', alignItems: 'flex-start' }}>
                                                    <input type="checkbox" id="checkbox-email-consent" class="form-check-input" style={{ marginTop: '2px' }} checked={isEmailConsent ? 'checked' : ''} onChange={(e) => setIsEmailConsent(e.target.checked)} />
                                                    <label for="checkbox-email-consent" class="form-check-label" style={{ marginLeft: '5px' }}>I want to receive email notifications for this order on email address provided above.</label>
                                                </div>
                                            </div>

                                            <div className='col'>
                                                <p className='form-label'>Your mobile number</p>
                                                <input
                                                    value={mobileNumber}
                                                    onChange={ (e) => setMobileNumber(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your mobile number'
                                                    required />

                                                <div className="d-flex" style={{ marginTop: '-13px', marginBottom: '20px', fontSize: '14px', lineHeight: '19px', alignItems: 'flex-start' }}>
                                                    <input type="checkbox" id="checkbox-phone-consent" class="form-check-input" style={{ marginTop: '2px' }} checked={isPhoneConsent ? 'checked' : ''} onChange={(e) => setIsPhoneConsent(e.target.checked)} />
                                                    <label for="checkbox-phone-consent" class="form-check-label" style={{ marginLeft: '5px' }}>I want to receive sms notifications for this order on mobile number provided above. I understand I will receive confirmation and status messages for this order, message and data rates may apply.</label>
                                                </div>
                                            </div>
                                            
                                            <div className='col'>
                                                <p className='form-label'>Your state</p>
                                                <div style={{ position: 'relative' }}>
                                                    <select className='form-control' onChange={(e) => setState(e.target.value)} required>
                                                        <option hidden>Select your state</option>
                                                        {states?.map(item => (
                                                            <option key={item.name} value={item.name}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <Icon icon={'ph:caret-down-bold'} color='#555' width={20} height={20} style={{ position: 'absolute', top: '8px', right: '10px' }} />
                                                </div>
                                            </div>
                                            
                                            <div className='col'>
                                                <p className='form-label'>Your city</p>
                                                <div style={{ position: 'relative' }}>
                                                    <select className='form-control' onChange={(e) => setCity(e.target.value)} required>
                                                        <option hidden>{state ? 'Select your city' : 'Select your state first'}</option>
                                                        {cities?.map(item => (
                                                            <option key={item} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                    <Icon icon={'ph:caret-down-bold'} color='#555' width={20} height={20} style={{ position: 'absolute', top: '8px', right: '10px' }} />
                                                </div>
                                            </div>

                                            <div className='col col-md-12'>
                                                <p className='form-label'>Your complete address</p>
                                                <textarea
                                                    value={address}
                                                    onChange={ (e) => setAddress(e.target.value) }
                                                    className='form-control'
                                                    placeholder='Enter your address'
                                                    rows={3} 
                                                    required ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 text-center'>
                                        <button type='submit' disabled={isLoading} className="yellow-button">{isLoading ? 'Please wait...' : 'Sell your phone'}</button>
                                    </div>
                                </div>
                                
                                <p style={{ fontSize: '15px', textAlign: 'center' }}>By submitting, you agree to your <Link to="/terms-conditions" className='link-blue' style={{ fontWeight: '500' }}>Terms &amp; conditions</Link> and <Link to="/privacy-policy" className='link-blue' style={{ fontWeight: '500' }}>Privacy policy</Link></p>
                            </form>
                        </div>

                        <div className={`${step == 10 ? 'd-block' : 'd-none'}`}>
                            <div className="banner_text">
                                <h2>Request Received</h2>
                                <p>Thank you for submitting your phone and contact details. We will contact you at the provided email address or mobile number to give you exact value of your phone.</p>
                            </div>

                            <div className='information-box'>
                                <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3'>
                                    <div className='col' style={{ flex: '0 0 100%', maxWidth: '100%' }}><p className='mb-2'>Below are the details you submitted.</p></div>
                                    <div className='col'><p className='information-label'>Request number: <span>{instantCash?.order_number}</span></p></div>
                                    <div className='col'><p className='information-label'>Request date: <span>{instantCash?.request_date}</span></p></div>
                                    <div className='col'><p className='information-label'>Model: <span>{instantCash?.title}</span></p></div>
                                    <div className='col'><p className='information-label'>Name: <span>{instantCash?.name}</span></p></div>
                                    <div className='col'><p className='information-label'>Email: <span>{instantCash?.email}</span></p></div>
                                    <div className='col'><p className='information-label'>Mobile number: <span>{instantCash?.mobile_number}</span></p></div>
                                    <div className='col'><p className='information-label'>State: <span>{instantCash?.state}</span></p></div>
                                    <div className='col'><p className='information-label'>City: <span>{instantCash?.city}</span></p></div>
                                    <div className='col'><p className='information-label'>Address: <span>{instantCash?.address}</span></p></div>
                                    <div className='col'><p className='information-label'>Provider: <span>{instantCash?.provider}</span></p></div>
                                    <div className='col'><p className='information-label'>Phone broken: <span>{instantCash?.is_broken} {instantCash?.broken_sides ? '('+instantCash?.broken_sides+')' : ''}</span></p></div>
                                    {instantCash?.is_broken == 'No' ? (
                                        <>
                                            <div className='col'><p className='information-label'>Phone condition: <span>{instantCash?.q1}</span></p></div>
                                            <div className='col'><p className='information-label'>Screen scratches condition: <span>{instantCash?.q2}</span></p></div>
                                            <div className='col'><p className='information-label'>Battery health: <span>{instantCash?.q4}</span></p></div>
                                            <div className='col'><p className='information-label'>Body damage: <span>{instantCash?.q5}</span></p></div>
                                            <div className='col'><p className='information-label'>Phone back condition: <span>{instantCash?.q6}</span></p></div>
                                        </>
                                    ) : null}
                                    <div className='col'><p className='information-label'>Original charger available: <span>{instantCash?.charger}</span></p></div>
                                    <div className='col'><p className='information-label'>Original IMEI box available: <span>{instantCash?.box}</span></p></div>
                                    <div className='col'><p className='information-label'>{instantCash?.issues ? 'Issues with' : 'Issues'}: <span>{instantCash?.issues ? instantCash?.issues : 'No issue'}</span></p></div>
                                    <div className='col' style={{ flex: '0 0 100%', maxWidth: '100%' }}><button onClick={handleReset} className="yellow-button mb-0 mt-2">Sell another phone</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#f6f4fe" />
                    </svg>
                </div>
            </section>

            <section className="row_am faq_section">
                <div className="container">
                    <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>How </span> It Works</h2>
                            <p>Learn how to sell your phone easily on CellyCash.</p>
                        </div>
                        <div className='row row-cols-1 row-cols-sm-3 process-row mt-3'>
                            <div className='col'>
                                <div className='process-item'>
                                    <div className='img-container'>
                                        <img src='/images/services-search.svg'/>
                                    </div>
                                    <p className='process-title'>Find Your Phone</p>
                                    <p className='process-description'>Find your phone and share details like model, issues, and condition.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='process-item'>
                                    <div className='img-container'>
                                        <img src='/images/services-offer.svg'/>
                                    </div>
                                    <p className='process-title'>Get Our Offer</p>
                                    <p className='process-description'>Receive our offer based on the information you provided about your phone.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='process-item'>
                                    <div className='img-container'>
                                        <img src='/images/services-pickup.svg'/>
                                    </div>
                                    <p className='process-title'>Get Instant Cash</p>
                                    <p className='process-description'>We come to your doorstep, pick up your phone, and hand you instant cash.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="faqs" style={{position: 'relative', top: '-80px'}}></div>
            <FaqsSection />
        </>
    );
}
 
export default InstantCashBrand;
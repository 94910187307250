import { Link } from 'react-router-dom';
import FaqsSection from './FaqsSection';

const Home = () => {
    document.title = "CellyCash";
    
    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12"  data-aos="fade-right" data-aos-duration="1500">
                            <div className="banner_text">
                                <h1>Sell your old phone<br />&amp; get Instant Cash</h1>
                                <p>CellyCash - Discover a hassle-free way to sell your old Apple and Samsung phones and receive instant cash at your doorstep for your valuable devices.</p>
                            </div>
                            <Link to="/instant-cash" tabIndex="0" className="instant-cash-button" data-aos="fade-in" data-aos-duration="1500">Get Instant Cash</Link>
                            {/* <div>
                                <a href="https://play.google.com/store/apps/details?id=com.apnaphone.app" className="banner-download" data-aos="fade-in" data-aos-duration="1500"><img src="/images/google-play-button.svg" alt="Download button"/></a>
                            </div> */}
                        </div>

                        <div className="col-lg-4 col-md-12"  data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_image">
                                <img className="moving_animation" src="/images/banner-image.png" alt="Phones"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" fill="#ffffff" />
                    </svg>
                </div>
            </section>

            <div id="about" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am about_app_section white-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src="/images/about-money.svg" alt="Cash"/>
                                </div>
                                <div className="screen_img zindex-2">
                                    <img className="moving_animation" src="/images/about-phone.jpg" alt="Phone"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>About</span> CellyCash</h2>
                                    <p>CellyCash provides a hassle-free way to sell your old Apple and Samsung phones and receive instant cash at your doorstep for your valuable devices.</p>
                                    <p>Our mission at CellyCash is to simplify device transactions. Experience a straightforward process that puts you in control.</p>
                                    <p>Trust is key at CellyCash. We're transparent from phone details to cash exchange—your devices are in reliable hands.</p>
                                </div>

                                <Link to="/instant-cash" tabIndex="0" className="instant-cash-button" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">Get Instant Cash</Link>

                                {/* <div>
                                    <a href="https://play.google.com/store/apps/details?id=com.apnaphone.app" className="about-download" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="500"><img src="/images/google-play-button.svg" alt="Download button"/></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row_am faq_section">
                <div className="container">
                    <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>How </span> It Works</h2>
                            <p>Learn how to sell your phone easily on CellyCash.</p>
                        </div>
                        <div className='row row-cols-1 row-cols-sm-3 process-row mt-3'>
                            <div className='col'>
                                <div className='process-item'>
                                    <div className='img-container'>
                                        <img src='/images/services-search.svg'/>
                                    </div>
                                    <p className='process-title'>Find Your Phone</p>
                                    <p className='process-description'>Find your phone and share details like model, issues, and condition.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='process-item'>
                                    <div className='img-container'>
                                        <img src='/images/services-offer.svg'/>
                                    </div>
                                    <p className='process-title'>Get Our Offer</p>
                                    <p className='process-description'>Receive our offer based on the information you provided about your phone.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='process-item'>
                                    <div className='img-container'>
                                        <img src='/images/services-pickup.svg'/>
                                    </div>
                                    <p className='process-title'>Get Instant Cash</p>
                                    <p className='process-description'>We come to your doorstep, pick up your phone, and hand you instant cash.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div id="why-choose" style={{position: 'relative', top: '-80px'}}></div>
            <section className="row_am modern_ui_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>Models </span> We Accept</h2>
                                    <p>We accept popular models of Apple and Samsung. Turn your phone into cash with a simple process.</p>
                                </div>

                                <ul className="design_block">
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Apple iPhones</h4>
                                        <p>Major iPhones includes iPhone 14, iPhone 13, iPhone 12, iPhone 11, iPhone SE.</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Samsung mobiles</h4>
                                        <p>Major samsung mobiles including Galaxy A series, Galaxy S series, Galaxy F series.</p>
                                    </li>
                                </ul>
                                
                                <Link to="/instant-cash" tabIndex="0" className="instant-cash-button" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">Get Instant Cash</Link>
                            </div>
                        </div>

                        <div className="col-lg-5 mt-3 mt-lg-0 d-flex d-lg-block">
                            <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                                <div className="frame_img">
                                    <img className="moving_position_animatin" src="/images/apple.svg" alt="Apple"/>
                                </div>
                                <div className="screen_img moving_animation zindex-2">
                                    <img src="/images/samsung.svg" alt="Samsung"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="faqs" style={{position: 'relative', top: '-80px'}}></div>
            <FaqsSection />
        </>
    );
}
 
export default Home;
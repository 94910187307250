import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { store } from '../redux/store';
import { setUser } from '../redux/userSlice';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const Register = ({navigation}) => {
    const user = useSelector(state => state.user);

    const navigate = useNavigate()

    const mainViewRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
    const [isLoadingFacebook, setIsLoadingFacebook] = useState(false);
    const [error, setError] = useState(false)
    const [errorSocial, setErrorSocial] = useState(false)
    const [validationErrors, setValidationErrors] = useState([]);
    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()

        setIsLoading(true);
        setValidationErrors([]);
        setError(false);

        let formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('password', password);
        formData.append('password_confirmation', passwordConfirm);
        
        fetch('https://api.cellycash.com/register', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                store.dispatch(setUser({
                    token: data?.access_token,
                    id: data?.user?.id,
                    name: data?.user?.name,
                    email: data?.user?.email,
                    phone: data?.user?.phone,
                    registered_with: data?.user?.registered_with,
                }))

                navigate('/')
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: response => {
            setIsLoadingGoogle(true)

            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
                method: 'GET',
                headers: { 
                    "Accept": "application/json",
                    "Authorization": `Bearer ${response.access_token}`,
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error('There was some error while logging in, please try again.');
                }

                return response.json();
            })
            .then(data => {
                handleSocialLogin('google', data?.id ?? '', data?.name ?? '', data?.email ?? '');
            })
            .catch(error => {
                setErrorSocial(error.message)
                setIsLoadingGoogle(false)
            })
        },
        onError: error => {
            setErrorSocial(error?.message ?? 'There was some error while logging in, please try again.')
            setIsLoadingGoogle(false)
        },
    });

    const handleFacebookLogin = (response) => {
        handleSocialLogin('facebook', response?.id ?? '', response?.name ?? '', response?.email ?? '');
    }

    function handleSocialLogin(provider, id, name, email) {
        let formData = new FormData();
        formData.append('provider', provider);
        formData.append('provider_id', id);
        formData.append('name', name);
        formData.append('email', email);
        
        fetch('https://api.cellycash.com/social-login', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            if (provider == 'google') setIsLoadingGoogle(false);
            else setIsLoadingFacebook(false);

            if (data?.status == 'success') {
                store.dispatch(setUser({
                    token: data?.access_token,
                    id: data?.user?.id,
                    name: data?.user?.name,
                    email: data?.user?.email,
                    phone: data?.user?.phone,
                    registered_with: data?.user?.registered_with,
                }))

                navigate('/')
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            
            if (provider == 'google') setIsLoadingGoogle(false);
            else setIsLoadingFacebook(false);
        })
    }

    useEffect(() => {
        if (user?.token)
        {
            navigate('/')
        }
    }, [user?.token]);

    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div ref={mainViewRef} className="container">
                    <div className="banner_text">
                        <h2>Register</h2>
                        <p>Create your CellyCash account</p>
                    </div>

                    <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                        <form onSubmit={handleSubmit} className='text-left'>
                            { error && <div className="alert-msg-danger" style={{ width: '100%' }}><p className="mb-0">{ error }</p></div> }
                            { validationErrors.length > 0 &&
                                <div className="alert-msg-danger" style={{ width: '100%' }}>
                                    <h3>There was some error in submitted information, please try again.</h3>
                                    <ul>
                                        { validationErrors.map((validationError, index) => (
                                            <li key={index}>{ validationError }</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            
                            <p className='form-label'>Your full name</p>
                            <input
                                value={name}
                                onChange={ (e) => setName(e.target.value) }
                                className='form-control'
                                placeholder="Enter your full name"
                                required
                            />

                            <p className='form-label'>Your email address</p>
                            <input
                                value={email}
                                onChange={ (e) => setEmail(e.target.value) }
                                className='form-control'
                                placeholder="Enter your email address"
                                type='email'
                                required
                            />

                            <p className='form-label'>Your mobile number</p>
                            <input
                                value={phone}
                                onChange={ (e) => setPhone(e.target.value) }
                                className='form-control'
                                placeholder="Enter your mobile number"
                                required
                            />

                            <p className='form-label'>Your password</p>
                            <input
                                value={password}
                                onChange={ (e) => setPassword(e.target.value) }
                                className='form-control'
                                placeholder="Enter your password"
                                type='password'
                                required
                            />

                            <p className='form-label'>Confirm password</p>
                            <input
                                value={passwordConfirm}
                                onChange={ (e) => setPasswordConfirm(e.target.value) }
                                className='form-control'
                                placeholder="Re-enter your password"
                                type='password'
                                required
                            />
                                
                            <div className="text-center">
                                <button type='submit' disabled={(isLoading || isLoadingGoogle || isLoadingFacebook)} className="yellow-button">{isLoading ? 'Please wait...' : 'Register'}</button>
                            </div>
                        </form>

                        <p style={{ fontSize: '15px', textAlign: 'center' }}>Already have an account? <Link to={"/login"} className='link-blue' style={{ fontWeight: '500' }}>Login Now</Link></p>

                        { errorSocial && <div className="alert-msg-danger" style={{ width: '100%' }}><p className="mb-0">{ errorSocial }</p></div> }
                        
                        <FacebookLogin 
                            appId="943290393835401"
                            scope="public_profile,email"
                            callback={handleFacebookLogin}
                            render={renderProps => (
                                <Link 
                                    onClick={renderProps.onClick} 
                                    disabled={(isLoading || isLoadingGoogle || isLoadingFacebook)}
                                    className='social-button'
                                    style={{ backgroundColor: '#2374F2', border: '1px solid #2374F2', margin: '0px auto 10px' }}>
                                    <img src='/images/facebook-logo.svg' style={{ width: '20px' }} />
                                    <p style={{ color: '#ffffff' }}><span>{isLoadingFacebook ? 'Please wait...' : 'Login with Facebook'}</span></p>
                                </Link>
                            )}
                        />

                        <Link 
                            onClick={() => {
                                setErrorSocial(false);
                                handleGoogleLogin()
                            }} 
                            disabled={(isLoading || isLoadingGoogle || isLoadingFacebook)}
                            className='social-button'
                            style={{ backgroundColor: '#F2F2F2', border: '1px solid #747775', margin: '0px auto' }}>
                            <img src='/images/google-logo.svg' style={{ width: '20px' }} />
                            <p><span>{isLoadingGoogle ? 'Please wait...' : 'Login with Google'}</span></p>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default Register;
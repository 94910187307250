import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AdminApp from "./Components/App";
import ScrollToTop from "./Components/ScrollToTop";
import "./fontawesome";
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <GoogleOAuthProvider clientId="118300237555-ljlj398lrd39ug0dp784e4kua8eeh9n4.apps.googleusercontent.com">
        <React.StrictMode>
            <BrowserRouter>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ScrollToTop />
                        <AdminApp />
                    </PersistGate>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>
);

import { Link } from 'react-router-dom';

const TermsConditions = ({navigation}) => {
    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div className="container">
                    <div className="banner_text mb-4">
                        <h2>Terms And Conditions</h2>
                    </div>
                    
                    <div style={{ fontSize:'14px', textAlign: 'left' }}>
                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Terms and Conditions for CellyCash services:</h3>
                        <p>By using it, you are agreeing to these Terms & Conditions (defined below). Please read them carefully. CellyCash LLC doing business as “CellyCash”</p>
                        <p>“CellyCash” owns and operates the website, <a className='link-blue' href="www.cellycash.com">www.cellycash.com</a> The mobile touch versions, App on Play store or any site(s) we have now or in the future that reference these Terms & Conditions (collectively, “CellyCash”), provides a marketplace and platform for consumers and organizations to sell or repair their used, old and other articles, and conduct varied transactions and activities, with third-party companies and other entities and persons (collectively, “Third Parties”). The CellyCash marketplace, platform, and related functionality, whether provided through the Site or through other means, are collectively referred to as the services (the “Services”).</p>

                        <p class="mb-1">If you do not agree with any part of these Terms & Conditions, you must not use the Site or Services. Your continued use of the Site or Services will constitute your acceptance of these Terms & Conditions, as they may be modified by us from time to time, with or without notice to you. Please check this page regularly for updates to the CellyCash Terms & Conditions.</p>
                        <ul className='unstyled' style={{listStyle: 'decimal', marginBottom: '10px'}}>
                            <li className='unstyled'>
                                <p class="mb-1">CellyCash is a marketplace venue.</p>
                                <p>CellyCash is a platform to allow users, subject to compliance with CellyCash’s policies, to sell/repair certain goods. CellyCash may not be directly involved in the transaction between user(s) and third party professional(s), ensuing no control by reasons whatsoever in any aspect of your transactions with Third Parties, and the Third Parties are solely responsible to you for all aspects of your transactions with them.</p>
                            </li>
                            <li className='unstyled'>
                                <p class="mb-1">Permitted Use and Compliance with Laws.</p>
                                <p>CellyCash authorizes you to access, view and use the Site content and software (collectively, the “CellyCash LLC”) solely to the extent necessary for you to use the Services. You may not remove any copyright, trademark or other proprietary notices that have been placed on the CellyCash Property. You may not engage in systematic retrieval of data or other content from the CellyCash Property. Except as expressly permitted by these Terms & Conditions, any modification, reproduction, redistribution, republication, uploading, posting, transmitting, distributing or otherwise exploiting in any way the CellyCash Property, or any portion of the CellyCash Property, is strictly prohibited without the prior written permission of CellyCash LLC</p>
                            </li>
                            <li className='unstyled'><p>You agree that you will comply with all applicable laws, regulations and ordinances relating to the Site and Services, the CellyCash Property or your use of them, and that in using the Site and Services you will not engage in any conduct that restricts or inhibits any other person from using or enjoying the Services.</p></li>
                            <li className='unstyled'>
                                <p class="mb-1">CellyCash Services and Third Party Services and Sites</p>
                                <p class="mb-1">The information and materials provided in the Site and through the Services are intended for general reference only, and may not describe all of the terms, conditions, and exceptions applicable to CellyCash’s Services. CellyCash presents information from Third Parties through the CellyCash Site and Services, including prices offered for your items, item descriptions, certain Third Party terms of service, and other information (“Third Party Information”). CellyCash cannot control, and is not responsible for the accuracy of any Third Party Information.</p>
                                <p class="mb-1">You conduct your actual sales and other transactions directly with the Third Parties and, unless otherwise specifically and clearly indicated, not with CellyCash. CellyCash cannot control any aspect of your sales and transactions with Third Parties, and the Third Parties are solely responsible to you for all aspects of your sales and transactions with them.</p>
                                <p>At times, the CellyCash Site may have links to websites hosted by the Third Parties and other entities (the “Additional Sites”), or such Additional Sites may have links to the CellyCash Site. These links are offered as a convenience and for informational purposes only, not as referrals or endorsements by CellyCash of the Additional Sites. The Additional Sites are maintained by their respective organizations, and those organizations are solely responsible for the content of their own websites. CellyCash does not verify nor make any warranty or representation about the content, accuracy, opinions expressed, warranties, products or services, intellectual property compliance, or links of such Additional Sites. You should read the privacy policies and Terms & Conditions agreements of all Additional Sites.</p>
                            </li>
                            <li className='unstyled'>
                                <p class="mb-1">Privacy and Passwords</p>
                                <p>CellyCash values and protects the privacy of your information. Please review the CellyCash privacy policy, as it contains important information relating to your use of the Site and Services. Some portions of the Site are protected and require a user identification code (“User ID”) and password for access. Unauthorized access or use of such portions of the Site is prohibited. You agree that you will notify CellyCash immediately if you believe that a third party has obtained your User ID or password, or if you believe that any unauthorized access or use may occur or has occurred. For your protection, if CellyCash believes that any unauthorized access may occur or has occurred, CellyCash may terminate access without prior notice to you. You also agree that CellyCash is permitted to act upon any instructions received such instructions as authorized by you.</p>
                            </li>
                            <li className='unstyled'>
                                <p class="mb-1">Membership</p>
                                <ul className='unstyled' style={{listStyle: 'lower-alpha', marginBottom: '10px'}}>
                                    <li className='unstyled'>
                                        Registration and Basic Membership Terms: Members are visitors to the site and or those using the Services that choose to register with CellyCash (“Members”). Once registered, an account is created for each Member (“Account”). If you choose to register as a Member, you represent and warrant to us that:
                                        <ul className='unstyled' style={{listStyle: 'lower-roman', marginBottom: '10px'}}>
                                            <li className='unstyled'>you are of legal age to form a binding contract</li>
                                            <li className='unstyled'>you will provide us with accurate, current and complete registration and contact information, and keep your information updated</li>
                                            <li className='unstyled'>your registration and your use of the Services are not prohibited by law. We reserve the right to suspend or terminate your membership, or your access to the Site and or Services, in the event that you breach any of the Terms & Conditions or other applicable CellyCash policies.</li>
                                        </ul>
                                        <li className='unstyled'>Password and Account Security: In connection with your Account, you will create a password and a user id. You are responsible for keeping your password and secure, and you are responsible for all actions taken using your password.</li>

                                        <li className='unstyled'>Age: To create an Account or to otherwise use this Website, you must be at least eighteen (18) years old or the age of majority in your state or province of residence, whichever is greater.</li>
                                    </li>
                                </ul>
                            </li>

                            <li className='unstyled'>
                                <p class="mb-1">Inactive Accounts</p>
                                <p>Inactive Status: A Member’s Account may be set to inactive if there is no activity associated with that Account for 180 days.</p>
                            </li>
                        </ul>

                        <p>You understand that agreeing to the run diagnostics on Apna app program will allow CellyCash access to your device, solely in relation to conducting the assessment of the quality parameters of your device. In no matter will your private information and data on the device will be accessed for any other purpose and that such information shall not be downloaded by the Company or used in any other manner.</p>

                        <h3 style={{ fontSize: '18px' }} className='mb-2'>SMS Notifications</h3>
                        <p>We use Twilio for SMS notifications. We ask for user's consent for every order they place. User can choose whether they want to receive sms updates or not. We only send order confirmation and order status change updates for selected order. We will never send any irrelevant, promotional or marketing to your phone number.</p>
                        
                        <h3 style={{ fontSize: '18px' }} className='mb-2'>Contact us</h3>
                        <p class="mb-1">Email: <a className='link-blue' href="mailto:info@cellycash.com">info@cellycash.com</a></p>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default TermsConditions;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    id: null,
    name: null,
    email: null,
    phone: null,
    registered_with: null,
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialState, 
    reducers: {
        setUser: (state , action)=> {
            return action.payload
        },
        removeUser: (state, action)=> {
            return initialState
        },
        updateUser: (state, action)=> {
            state[action.payload.key] = action.payload.value
        },
    }
})

export const { setUser, removeUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useContext, useEffect, useState, useRef } from 'react';
import useFetch from '../useFetch';

/*
steps
1. Brand
2. Model
3. Variant
4. Cost
5. Provider
6. Condition
7. Issues
8. Form
*/

const FaqsSection = () => {
    return (
        <section className="row_am faq_section">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2>Frequently Asked <span>Questions</span></h2>
                    <p>Need help getting started with CellyCash? Our FAQs have got you covered<br/> by providing clear and concise answers to all your questions.</p>
                </div>

                <div className="faq_panel">
                    <Accordion defaultActiveKey="0">
                        <div className="card" data-aos="fade-up" data-aos-duration="1500">
                            <div className="card-header">
                                <CustomAccordianToggle eventKey="0">In which states CellyCash available?</CustomAccordianToggle>
                            </div>
                            <Accordion.Collapse eventKey="0">
                                <div className="card-body">
                                    <p>CellyCash is available currently in California, Texas and Florida.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                        <div className="card" data-aos="fade-up" data-aos-duration="1500">
                            <div className="card-header">
                                <CustomAccordianToggle eventKey="1">How can I sell my phone?</CustomAccordianToggle>
                            </div>
                            <Accordion.Collapse eventKey="1">
                                <div className="card-body">
                                    <p>You can submit Instant Cash request by entering details about mobile phones, we will make an offer for your phone then give you instant cash at your dootstep.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </div>
        </section>
    )
}

function CustomAccordianToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <h2 className="mb-0" onClick={decoratedOnClick}>
            <button type="button" className={`btn btn-link ${isCurrentEventKey ? 'active' : ''}`} tabIndex="-1">
                {children}
                {isCurrentEventKey ? (
                    <img src="/images/icon-minus.svg" alt="View less" className="icon_faq minus"/>
                ) : (
                    <img src="/images/icon-plus.svg" alt="View more" className="icon_faq plus"/>
                )}
            </button>
        </h2>
    );
}
 
export default FaqsSection;
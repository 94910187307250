import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { store } from '../redux/store';
import { setUser } from '../redux/userSlice';

const ForgotPassword = ({navigation}) => {
    const user = useSelector(state => state.user);

    const navigate = useNavigate()

    const mainViewRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)
    const [validationErrors, setValidationErrors] = useState([]);
    const [success, setSuccess] = useState(false)
    const [errorResend, setErrorResend] = useState(false)
    const [successResend, setSuccessResend] = useState(false)
    const [sendMessage, setSendMessage] = useState(false)
    
    const [email, setEmail] = useState('')
    const [hash, setHash] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const handleSend = (e) => {
        e.preventDefault()

        setIsLoading(true);
        setSendMessage(false);
        setValidationErrors([]);
        setError(false);

        let formData = new FormData();
        formData.append('email', email);
        
        fetch('https://api.cellycash.com/forgot-password', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSendMessage(data?.message)
                setHash(data?.hash)
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleResend = () => {
        if (isLoading || errorResend !== false || successResend !== false) return

        setIsLoading(true);
        setSuccessResend(false)
        setErrorResend(false);

        let formData = new FormData();
        formData.append('hash', hash);
        
        fetch('https://api.cellycash.com/resend-forgot-password', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Code not sent, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccessResend(data?.message)
            }
            else if (data?.status == 'validation_error')
            {
                setErrorResend(data.messages[0]);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setErrorResend(data.message);
            }
            else if (data?.status == 'error-reset' && data?.message)
            {
                setError(data.message);
                setSendMessage(false);
                setEmail('')
                setHash('')
                setCode('')
                setPassword('')
                setPasswordConfirm('')
            }
            else
            {
                setErrorResend('Code not sent, please try again.');
            }
            setTimeout(() => {
                setSuccessResend(false);
                setErrorResend(false);
            }, 4000);
        })
        .catch(error => {
            setErrorResend(error.message);
            setIsLoading(false);
            
            setTimeout(() => {
                setSuccessResend(false);
                setErrorResend(false);
            }, 4000);
        })
    }

    const handleReset = (e) => {
        e.preventDefault()

        setIsLoading(true);
        setError(false);

        let formData = new FormData();
        formData.append('hash', hash);
        formData.append('code', code);
        formData.append('password', password);
        formData.append('password_confirmation', passwordConfirm);
        
        fetch('https://api.cellycash.com/reset-password', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data?.message)
                setError(false);
                setSendMessage(false);
                setEmail('')
                setHash('')
                setCode('')
                setPassword('')
                setPasswordConfirm('')
            }
            else if (data?.status == 'validation_error')
            {
                setError(data.messages[0]);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else if (data?.status == 'error-reset' && data?.message)
            {
                setError(data.message);
                setSendMessage(false);
                setEmail('')
                setHash('')
                setCode('')
                setPassword('')
                setPasswordConfirm('')
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (user?.token)
        {
            navigate('/')
        }
    }, [user?.token]);

    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div ref={mainViewRef} className="container">
                    <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                        {success ? (
                            <div className="banner_text">
                                <h2>Password Reset</h2>
                                <p>{success}</p>
                                <div className="text-center">
                                    <Link to={"/login"} className="yellow-button">Login Now</Link>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="banner_text">
                                    <h2>Forgot Password</h2>
                                    <p>Reset your CellyCash password</p>
                                </div>

                                <form onSubmit={hash ? handleReset : handleSend} className='text-left'>
                                    { sendMessage && <div className="alert-msg-success" style={{ width: '100%' }}><p className="mb-0">{ sendMessage }</p></div> }
                                    { error && <div className="alert-msg-danger" style={{ width: '100%' }}><p className="mb-0">{ error }</p></div> }
                                    { validationErrors.length > 0 &&
                                        <div className="alert-msg-danger" style={{ width: '100%' }}>
                                            <h3>There was some error in submitted information, please try again.</h3>
                                            <ul>
                                                { validationErrors.map((validationError, index) => (
                                                    <li key={index}>{ validationError }</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                    
                                    <p className='form-label'>Your email address</p>
                                    <input
                                        value={email}
                                        onChange={ (e) => setEmail(e.target.value) }
                                        className='form-control'
                                        placeholder='Enter your email address'
                                        type='email'
                                        disabled={hash !== ''}
                                        required />

                                    {hash ? (
                                        <>                                        
                                            <p className='form-label d-flex align-items-center justify-content-between'>
                                                <span>Code</span>
                                                <Link onClick={handleResend} className='link-blue' style={{ fontSize: '13px', textAlign: 'right' }}>Resend<span className='d-none d-sm-inline'> Code</span></Link>
                                            </p>
                                            <input
                                                value={code}
                                                onChange={ (e) => setCode(e.target.value) }
                                                className='form-control'
                                                placeholder='Enter code'
                                                required />
                                                
                                            {errorResend ? <p style={{ color: '#dc3545', fontSize: '14px', lineHeight: '20px', marginTop: '-15px', marginBottom: '15px' }}>{errorResend}</p> : null}
                                            {successResend ? <p style={{ color: '#28a745', fontSize: '14px', lineHeight: '20px', marginTop: '-15px', marginBottom: '15px' }}>{successResend}</p> : null}

                                            <p className='form-label'>Your new password</p>
                                            <input
                                                value={password}
                                                onChange={ (e) => setPassword(e.target.value) }
                                                className='form-control'
                                                placeholder="Enter your new password"
                                                type='password'
                                                required
                                            />

                                            <p className='form-label'>Confirm password</p>
                                            <input
                                                value={passwordConfirm}
                                                onChange={ (e) => setPasswordConfirm(e.target.value) }
                                                className='form-control'
                                                placeholder="Re-enter your new password"
                                                type='password'
                                                required
                                            />
                                        </>
                                    ) : null}
                                        
                                    <div className="text-center">
                                        <button type='submit' disabled={isLoading} className="yellow-button">{isLoading ? 'Processing...' : (hash ? 'Reset Password' :'Send Reset Code')}</button>
                                    </div>
                                </form>

                                <p style={{ fontSize: '15px', textAlign: 'center' }}>Remember your password? <Link to={"/login"} className='link-blue' style={{ fontWeight: '500' }}>Login Now</Link></p>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default ForgotPassword;
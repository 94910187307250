import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { store } from '../redux/store';
import { setUser, updateUser } from '../redux/userSlice';

const UpdateProfile = ({navigation}) => {
    const user = useSelector(state => state.user);

    const navigate = useNavigate()

    const mainViewRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)
    const [validationErrors, setValidationErrors] = useState([]);
    const [success, setSuccess] = useState(false)
    
    const [name, setName] = useState(user.name ?? '')
    const [phone, setPhone] = useState(user.phone ?? '')

    const handleSubmit = (e) => {
        e.preventDefault()

        setIsLoading(true);
        setValidationErrors([]);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', name);
        formData.append('phone', phone);
        
        fetch('https://api.cellycash.com/user/account-details', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+user?.token
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while submitting form, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message)
                
                store.dispatch(updateUser({key: 'name', value: data?.user?.name}))
                store.dispatch(updateUser({key: 'phone', value: data?.user?.phone}))
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while submitting form, please try again.');
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (!user?.token)
        {
            navigate('/')
        }
    }, [user?.token]);

    return (
        <>
            <section className="banner_section subpage_banner_section white-bg center">
                <div ref={mainViewRef} className="container">
                    <div className="banner_text mb-4">
                        <h2>Update Profile</h2>
                    </div>

                    <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                        <form onSubmit={handleSubmit} className='text-left'>
                            { success && <div className="alert-msg-success" style={{ width: '100%' }}><p className="mb-0">{ success }</p></div> }
                            { error && <div className="alert-msg-danger" style={{ width: '100%' }}><p className="mb-0">{ error }</p></div> }
                            { validationErrors.length > 0 &&
                                <div className="alert-msg-danger" style={{ width: '100%' }}>
                                    <h3>There was some error in submitted information, please try again.</h3>
                                    <ul>
                                        { validationErrors.map((validationError, index) => (
                                            <li key={index}>{ validationError }</li>
                                        ))}
                                    </ul>
                                </div>
                            }

                            {user.registered_with == 'email' ? (
                                <>
                                    <p className='form-label'>Your email address</p>
                                    <input
                                        value={user.email}
                                        className='form-control'
                                        placeholder="Enter your email address"
                                        type='email'
                                        disabled
                                    />
                                </>
                            ) : null}

                            <p className='form-label'>Your full name</p>
                            <input
                                value={name}
                                onChange={ (e) => setName(e.target.value) }
                                className='form-control'
                                placeholder="Enter your full name"
                                required
                            />

                            <p className='form-label'>Your mobile number</p>
                            <input
                                value={phone}
                                onChange={ (e) => setPhone(e.target.value) }
                                className='form-control'
                                placeholder="Enter your mobile number"
                                required
                            />
                                
                            <div className="text-center">
                                <button type='submit' disabled={isLoading} className="yellow-button">{isLoading ? 'Please wait...' : 'Update Profile'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default UpdateProfile;